module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["tr","en","de","es","fars"],"defaultLanguage":"en","fallbackLanguage":"en","debug":true,"generateDefaultLanguagePage":true,"i18nextOptions":{"keySeparator":".","ignoreJSONStructure":false},"pages":[{"matchPath":"/","languages":["tr","en","de","es","fars"]},{"matchPath":"/about","languages":["tr","en","de","es","fars"]},{"matchPath":"/visual-media","languages":["tr","en","de","es","fars"]},{"matchPath":"/posts","languages":["tr"]},{"matchPath":"/contact","languages":["tr","en","de","es","fars"]},{"matchPath":"/digital-media","languages":["tr","en","de","es","fars"]},{"matchPath":"/digital-media","languages":["tr","en","de","es","fars"]},{"matchPath":"/thanks","languages":["tr","en","de","es","fars"]},{"matchPath":"/404","languages":["tr","en","de","es","fars"]},{"matchPath":"/:lang?/post/:path","getLanguageFromPath":true},{"matchPath":"/:lang?/digital-media/:path","getLanguageFromPath":true},{"matchPath":"/:lang?/:path","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-616622278","AW-616622278/BrJOCNmaoZIDEMbRg6YC"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
